import React, {useState, useEffect} from 'react'
import moment from 'moment'
import html2canvas from 'html2canvas';
import { Switch, Route } from "react-router-dom";
// import { ContextProvider } from "./home/components/Context"
import Header from './components/Header'
// import SelectService from './components/SelectService'
// import SearchDate from './components/SearchDate';
// import UserDetailForm from './components/UserDetail';
import { getAppInit, createAppointment } from './connect';
import RegForm from './components/RegForm';
import { Modal, Button } from 'react-bootstrap'


const regDataEmpty = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  location: "",
  referral_type: "",
  referral_value: "",
  reg_type: "",
  instagram_handle: "",
  wedding_location: "",
  wedding_date: "",
  vendor_category: [],
  other_vendor_category: "",
  business_name: "",
  vendor_offer: ""
}

// const appointmentData = {
//   userDetails: {},
//   appointmentDate: "",
//   timeSlot: "",
//   service: ""
// }

function App () {
  const [logoUrl, setLogoUrl] = useState("")
  const [vendorCat, setVendorCat] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [appCreated, setAppCreated] = useState(false)
  const [regType, setRegType] = useState("")
  const [regData, setRegData] = useState(regDataEmpty)
  const [showModal, setShowModal] = useState(false)
  const [brideExtraGuest, setBrideExtraGuest] = useState(false)
  
  useEffect(() => {
    document.title = "Bridesandbabies | The Bridal Experience"
    getAppInit()
    .then(result => {
      setLogoUrl(result.data.logoUrl)
      setVendorCat(result.data.vendor_category)
      setIsLoading(false)
    })
  }, [])

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowModal(true)
  //   }, 2000);
  // }, [regType])

  const handleAppointment = () => {
    let appointmentData = {
      regDetails: regData,
    }
    createAppointment(appointmentData).then(result => {
      console.log(result.data.status)
      if (result.data.status === "ok"){
        setAppCreated(true)
        setShowError(false)
      }else{
        setShowError(true)
      }
    })
    // console.log(appointmentData)
  }

  const regTypeChange = (value) =>{
    if(value=='Bride'){
      setRegType("Bride")
      setRegData(prev => {
        return{
          ...regDataEmpty, reg_type: 'Bride'
        }
      })
      setShowModal(true)
    }else{
      setRegType('Vendor')
      setRegData(prev => {
        return{
          ...prev, reg_type: 'Vendor'
        }
      })
    }
  }

  const saveAppCard = () => {
    // console.log('button click')
    html2canvas(document.querySelector('#appointmentCard')).then(function(canvas) {
      saveAs(canvas.toDataURL(), 'appointmentCard.png');
    });
  }

  function saveAs(uri, filename) {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = uri;
      link.download = filename;

      //Firefox requires the link to be in the body
      // var linkText = document.createTextNode("my title text");
      // link.appendChild(linkText);
      // link.title = "my title text";
      document.body.appendChild(link);

      //simulate click
      link.click();

      //remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  }

  if(isLoading){
    return (
      <div className="wrapper d-flex justify-content-center align-items-center" style={{height: '100%'}}>
          <div className="wrapper-content text-center">
            <div className="spinner-grow text-secondary" style={{width: '5rem', height: '5rem'} } role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
    )
  }

  else if (appCreated == false){
    return (
      <div className="wrapper">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <Header logoUrl={logoUrl} regType={regType} />  
            <div className="card">
              <div className='card-body'>
                  <RegForm regType={regType} setRegType={regTypeChange} regData={regData} setRegData={setRegData} vendorCat={vendorCat} handleAppointment={handleAppointment}/>
              </div>
            </div>  
          </div>
        </div>
      </div>

       <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Body>
          <div className="booking-policy">
            <h3 className='text-center'>Are you coming with a guest?</h3>
            <p className='text-center'>
            <Button variant={brideExtraGuest ? "primary" : 'secondary'} onClick={()=>setBrideExtraGuest(true)}>Yes</Button>
            <Button variant={brideExtraGuest ? "secondary" : 'primary'} onClick={()=>setBrideExtraGuest(false)}>No</Button>

            </p>
            {
              brideExtraGuest &&
              <h3 className='text-center mb-3'>Due to limitied space, only ONE guest may accompany each bride.</h3>

            }
            <p className='text-center'>
            <Button variant="primary" size="sm" onClick={handleClose}>
              Okay
            </Button></p>

          </div>
        </Modal.Body>
      </Modal>
      </div>

      
    )
  }else{

    return(
      <div className="wrapper">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
                <Header logoUrl={logoUrl} />
                {regType == "Bride" && 
                  <div id='appointmentCard'>
                    <div className="app-confirm">
                      <h5 className="text-center m-0">RSVP Confirmed</h5>
                      <div className="booking-policy">
                        {/* <h5 className="text-center m-0">Our Booking Policy</h5> */}
                        <p className="text-center m-0">Please note that The Bridal Experience is an intimate evening of targeted vendor-bride networking and as such, only brides and registered vendors may sign up.</p>
                        <p className="text-center m-0">Attendance is free for brides with incentives and special offers given for on-site vendor bookings</p> <br />
                        <p className="text-center m-0">*Masks are required for entry and must be left on during the event.</p> <br />
                        <p className="text-center m-0">*Brides & Babies occasionally shares stories of in-store activities on social media. If you would prefer not to appear on our stories, please inform us during your visit.</p>
                      </div>
                    </div>
                  </div>
                }

                {regType == "Vendor" && 
                  <div id='appointmentCard'>
                    <div className="app-confirm">
                      <div className="booking-policy">
                        <p className="text-center m-0">Dear vendor,</p>
                        <p className="text-center m-0">Thank you for your interest in The Bridal Experience Abuja. </p> 
                        <p className="text-center m-0">Your invoice will be sent shortly.</p>
                      </div>
                    </div>
                  </div>
                }

            </div>
          </div>
        </div>
      </div>

    )
  }


}

export default App