import React, {useState} from 'react'
import { Form, Button } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import Calendar from 'react-calendar';
import moment from 'moment';

const RegForm = (props) => {
  const [validated, setValidated] = useState(false)
  const [hasReferralValue, setHasReferralValue] = useState(false)
  const [otherVendorCat, setOtherVendorCat] = useState(false)

  const vendorCategoryList = props.vendorCat.map(vc => {
    return(
      <option key={vc.id} value={vc.name}>{vc.name}</option>
    )
  })

  const handleChange = (e) => {
    let {name,value} = e.target
    props.setRegData( prev => {
      if(e.target.id == "vendor_category"){
        // console.log([...e.target.options].filter(x => x.selected).map(x=> x.value))
        let temp_list = [...e.target.options].filter(x => x.selected).map(x=> x.value)
        if(temp_list.includes("Other")){
          temp_list = ["Other"]
          // setOtherVendorCat(true)
        }else{
          // setOtherVendorCat(false)
        }
        return{
          ...prev,
          [name]: temp_list
        }
      }else{
        return {
          ...prev,
          [name]: value
        }

      }
    })
    if(name=="referral_type"){
      if (value=='Other'){
        setHasReferralValue(true)
      }else{
        setHasReferralValue(false)
        props.setRegData( prev => {
          return { ...prev, referral_value: ""}
        })
      }

    }
    if(name=="vendor_category"){
      if (value=="Other"){
        setOtherVendorCat(true)
      }else{
        setOtherVendorCat(false)
      }

    }
  }

  const handleDateChange = (date) => {
    props.setRegData(prev => {
      return {
        ...prev,
        wedding_date: date
      }
    })
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }else{
      e.preventDefault()
      props.handleAppointment()
      setFormSubmit(true)
    }
    setValidated(true);
  }
  return(
    <>
    <div className='text-center mt-3 mb-3'>
      <Button className="btn-block" variant={props.regType == 'Bride' ? "primary" : 'secondary'} onClick={()=>props.setRegType("Bride")}>Bride</Button>
      <Button className="btn-block" variant={props.regType == 'Vendor' ? "primary" : 'secondary'} onClick={()=>props.setRegType("Vendor")}>Vendor</Button>
    </div>
    <div>
    <Form noValidate validated={validated} onSubmit={handleSubmit}>

    {
      (props.regType == "Bride" || props.regType == 'Vendor') &&
      <>
      <Form.Group className="mb-3" controlId="firstName">
          {/* <Form.Label>First Name</Form.Label> */}
          <Form.Control required type="text" placeholder="First Name" onChange={handleChange} name="firstName" value={props.regData.firstName}/>
          <Form.Control.Feedback type="invalid">
                Please enter your first name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="lastName">
          {/* <Form.Label>Last Name</Form.Label> */}
          <Form.Control required type="text" placeholder="Last Name" onChange={handleChange} name="lastName" value={props.regData.lastName}/>
          <Form.Control.Feedback type="invalid">
                Please enter your last name.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="email">
          {/* <Form.Label>Email</Form.Label> */}
          <Form.Control required type="email" placeholder="Email" onChange={handleChange} name="email" value={props.regData.email}/>
          <Form.Control.Feedback type="invalid">
                Please enter email.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="phone">
          {/* <Form.Label>Phone</Form.Label> */}
          <Form.Control required type="text" placeholder="Phone Number" onChange={handleChange} name="phone" value={props.regData.phone}/>
          <Form.Control.Feedback type="invalid">
                Please enter your phone number.
          </Form.Control.Feedback>
        </Form.Group>
      </>
    }

    {
      props.regType == "Bride" &&
      <>
      
        <Form.Group className="mb-3" controlId="instagram_handle">
          {/* <Form.Label>Phone</Form.Label> */}
          <Form.Control required type="text" placeholder="Instagram handle" onChange={handleChange} name="instagram_handle" value={props.regData.instagram_handle}/>
          <Form.Control.Feedback type="invalid">
                Please enter your Instagram handle.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="wedding_location">
          {/* <Form.Label>Location</Form.Label> */}
          <Form.Control required type="text" placeholder="Wedding Location(city)" onChange={handleChange} name="wedding_location" value={props.regData.wedding_location}/>
          <Form.Control.Feedback type="invalid">
                Please enter your wedding location.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="" controlId="wedding_date">
          <Form.Label>Wedding Date</Form.Label>
          {/* <Form.Control required type="text" placeholder="Wedding Date" onChange={handleChange} name="wedding_date" value={props.regData.wedding_date}  />
          <Form.Control.Feedback type="invalid">
                Please enter your wedding date.
          </Form.Control.Feedback> */}
        </Form.Group>

         <Calendar className="mb-3"
          onChange={(date) => handleDateChange(date)}
          minDate={moment().toDate()}
          /> 

        <Form.Group className="mb-3" controlId="referral_type">
          <Form.Select required className='custom-select form-select-lg' name="referral_type" onChange={handleChange} value={props.regData.referral_type} >
            <option value="">Referred By</option>
            <option value="Brides & Babies">Brides & Babies</option>
            <option value="Other">Other</option>
          </Form.Select>
        </Form.Group>

        { hasReferralValue && 
        
          <Form.Group required className="mb-3" controlId="referral_value">
            {/* <Form.Label>Location</Form.Label> */}
            <Form.Control type="text" placeholder="Please tell us how you heard about the event" onChange={handleChange} name="referral_value" value={props.regData.referral_value}/>
            <Form.Control.Feedback type="invalid">
                  Please fill in this field
            </Form.Control.Feedback>
          </Form.Group>
        
        }

        <Form.Group className='mb-3' controlId='vendor_category'>
        <Form.Label>What vendor category are you interested in?(you may select as many as you wish)</Form.Label>
          <Form.Select required multiple className='custom-select form-select-lg' name="vendor_category" onChange={handleChange} value={props.regData.vendor_category} >
            <option value="">Select Vendor Category</option>
            {vendorCategoryList}
          </Form.Select>
        </Form.Group>

        { otherVendorCat &&
          <>
          <Form.Group required className="mb-3" controlId="other_vendor_category">
            {/* <Form.Label>Location</Form.Label> */}
            <Form.Control type="text" placeholder="Please specify" onChange={handleChange} name="other_vendor_category" value={props.regData.other_vendor_category}/>
            <Form.Control.Feedback type="invalid">
                  Please enter category
            </Form.Control.Feedback>
          </Form.Group>
          </>
        }


      </>
    }

    {
      props.regType == 'Vendor' &&
      <>

        <Form.Group className="mb-3" controlId="business_name">
            {/* <Form.Label>Phone</Form.Label> */}
            <Form.Control required type="text" placeholder="Business name" onChange={handleChange} name="business_name" value={props.regData.business_name}/>
            <Form.Control.Feedback type="invalid">
                  Please enter your Business name.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="instagram_handle">
            {/* <Form.Label>Phone</Form.Label> */}
            <Form.Control required type="text" placeholder="Business Instagram handle" onChange={handleChange} name="instagram_handle" value={props.regData.instagram_handle}/>
            <Form.Control.Feedback type="invalid">
                  Please enter your Instagram handle.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="referral_type">
        <Form.Label>Referred By:</Form.Label>
          <Form.Select required className='custom-select form-select-lg' name="referral_type" onChange={handleChange} value={props.regData.referral_type} >
            <option value="">Please Select Referral</option>
            <option value="Brides & Babies">Brides & Babies</option>
            <option value="Other">Other</option>
          </Form.Select>
        </Form.Group>

        { hasReferralValue && 
        
          <Form.Group required className="mb-3" controlId="referral_value">
            {/* <Form.Label>Location</Form.Label> */}
            <Form.Control required type="text" placeholder="Referred By" onChange={handleChange} name="referral_value" value={props.regData.referral_value}/>
            <Form.Control.Feedback type="invalid">
                  Please enter your location.
            </Form.Control.Feedback>
          </Form.Group>
        
        }

        <Form.Group className='mb-3' controlId='vendor_category'>
        <Form.Label>Vendor Category</Form.Label>
          <Form.Select required className='custom-select form-select-lg' name="vendor_category" onChange={handleChange} value={props.regData.vendor_category}>
            <option value="">Select Vendor Category</option>
            {vendorCategoryList}
          </Form.Select>
        </Form.Group>

        { otherVendorCat &&
          <>
          <Form.Group required className="mb-3" controlId="other_vendor_category">
            {/* <Form.Label>Location</Form.Label> */}
            <Form.Control type="text" multiple placeholder="Please specify" onChange={handleChange} name="other_vendor_category" value={props.regData.other_vendor_category}/>
            <Form.Control.Feedback type="invalid">
                  Please enter category
            </Form.Control.Feedback>
          </Form.Group>
          </>
        }

        <Form.Group required className="mb-3"  >
          <Form.Check required type="radio" label="Full Participation: N40,000 (Physical slot granted)" name="vendor_offer" id="vendor_offer" value="full_participation" onChange={handleChange} /> <br />
          <Form.Check required type="radio" label="Vendor Guide Listing only: N15,000 (No physical participation but will be listed in our vendor guide for 1year)." name="vendor_offer" id="vendor_offer" value="vendor_guide" onChange={handleChange} /> <br />
          {/* <Form.Check required type="radio" label="I am interested in participating in this edition of The Bridal Experience Abuja, please send me an invoice" name="vendor_offer" id="vendor_offer" value="interested_invoice" onChange={handleChange} /> <br />
          <Form.Check required type="radio" label="I am not interested in participating in this edition of the Bridal Experience Abuja, but will like to be listed in your vendor guide, please send me an invoice" name="vendor_offer" id="vendor_offer" value="not_interested_invoice" onChange={handleChange}/> <br />
          <Form.Check required type="radio" label="I am not interested in either" name="vendor_offer" id="vendor_offer" value="not_interested" onChange={handleChange}/> <br /> */}

        </Form.Group>

      </>
      
    }
    {
      (props.regType == "Bride" || props.regType == 'Vendor') &&
        <Button variant="primary" size="sm" type="submit">
          Register
      </Button>
    }
    </Form>

    </div>
    </>
  )
}

export default RegForm