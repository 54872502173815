import React, {useState, useEffect} from 'react'
import { Modal, Button } from 'react-bootstrap'

const Header = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShow(true)
  //   }, 2000);
  // }, [props.regType])

  return(
    <div className="page-header mb-3">
      <div className="logo text-center mb-2">
        <img className="" src={props.logoUrl} />
      </div>
      <div className="row inner">
        <div className="col">
          <h2 className='text-center mt-3'>The Bridal Experience</h2>
        </div>
      </div>

      {/* <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="booking-policy">
            <h5>Our Booking Policy</h5>
            <p>Please note that we operate a 100% CASHLESS policy.</p>
            <p>All payments should be made using POS or Bank Transfer.</p>
            <p>Late shows for scheduled appointments over 15 minutes will be cancelled (please call to cancel/reschedule 2 hours in advance in the event of unforeseen circumstances).</p>
            <Button variant="primary" size="sm" onClick={handleClose}>
              Okay
            </Button>

          </div>
        </Modal.Body>
      </Modal> */}

    </div>
  )
}

export default Header