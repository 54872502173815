import axios from 'axios'
import moment from 'moment-timezone';

const instance = axios.create({
  baseURL: '/api'
});

export const getAppInit = () => {
  return instance.get('/init')
}

export const createAppointment = (appData) => {
  return instance.post('/createReg', {
    appData
  })
}